<template>
  <div class="change-phone">
    <heads :title="type=='add'?'绑定手机号':'修改手机号'"></heads>
    <div class="finish-content">
      <van-field
        placeholder="请输入手机号"
        type="digit"
        v-model="phoneNumber"
        maxlength="11"
        @blur="blurPhone"
        @input="changePhone"
        :readonly="codeContent=='获取验证码'?false:true"
      />
      <div class="get-code">
        <van-field
          type="digit"
          maxlength="6"
          placeholder="输入验证码"
          v-model="code"
        />
        <div
          :class="`${sendCodeFlag ? 'blue-text' : ''} code-text`"
          @click="sendCode"
        >
          {{codeContent}}{{codeContent!='获取验证码'?'s后重发':''}}
        </div>
      </div>

      <van-button
        :class="`${phoneNumber&&code?'sure-btn ':'sure-btn btn-opacity'}`"
        @click="save"
      >确认</van-button>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import { mapMutations } from "vuex";
import {
  getMobileCount,
  sendAuthSms,
  addMobile,
  getUserInfo,
  getOpenid,
} from "@/fetch/api/login";
import { getUrlCode } from "@/utils/validate";
export default {
  components: {
    heads: Header,
  },
  data() {
    return {
      codeContentTimer2: null,
      phoneNumber: null,
      code: null,
      codeContent: "获取验证码",
      sendCodeFlag: false,
      broker_id: null,
      type: this.$route.params.type, //change | add
    };
  },
  methods: {
    ...mapMutations(["SET_USERINFO"]),
    blurPhone() {
      // this.sendCodeFlag = false;
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber) && this.phoneNumber) {
        this.$toast("手机号码有误，请重填");
        return false;
      }
      // this.sendCodeFlag = true;
    },
    changePhone() {
      if (/^1[3456789]\d{9}$/.test(this.phoneNumber) && this.phoneNumber) {
        this.sendCodeFlag = true;
      } else {
        this.sendCodeFlag = false;
      }
    },
    sendCode() {
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
        this.$toast("请输入正确手机号");
        return;
      }
      getMobileCount({ mobile: this.phoneNumber }).then((res) => {
        if (res.code == 200) {
          if (res.result.count == 0) {
            this.getCode();
          } else {
            this.$toast("请更改手机号");
          }
        } else {
          this.$toast("请求失败");
        }
      });
    },
    getCode() {
      sendAuthSms({ mobile: this.phoneNumber }).then((res) => {
        if (res.code == 200) {
          if (!this.codeContentTimer2) {
            this.codeContent = 60;
            this.codeContentTimer2 = setInterval(() => {
              this.codeContent = this.codeContent - 1;
              if (this.codeContent < 1) {
                clearInterval(this.codeContentTimer2);
                this.codeContent = "获取验证码";
                this.codeContentTimer2 = null;
              }
            }, 1000);
          }
        } else {
          this.$toast("请求失败");
        }
      });
    },
    save() {
      if (!this.phoneNumber) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.code) {
        this.$toast("请输入验证码");
        return;
      }
      let params = {
        mobile: this.phoneNumber,
        sms_code: this.code,
      };
      addMobile(params).then((res) => {
        if (res.code == 200) {
          if (res.result.log_back == 0) {
            this.SET_USERINFO(res.user);
            this.$router.back(-1);
          } else {
            this.getCodeId();
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    getCodeId() {
      const code = getUrlCode().code;
      var local = window.location.href;
      var appid =
        process.env.NODE_ENV === "development"
          ? "wx67dbdad99e45d439"
          : "wx026553ce8b4e59a3";
      if (code == null || code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      } else {
        getOpenid({ code: code }).then((res) => {
          if (res.code == 200) {
            let token = res.result.token;
            let broker_id = res.result.broker_id;
            this.broker_id = broker_id;
            localStorage.setItem("token", token);
            localStorage.setItem("broker_id", broker_id);
            this.getUserInfoData();
          }
        });
      }
    },
    getUserInfoData() {
      getUserInfo({
        broker_id: this.broker_id,
      }).then((res) => {
        if (res.code == 200) {
          this.SET_USERINFO(res.result);
        }
      });
    },
  },
  created() {
    console.log(this.$route.params.type);
  },
  destroyed() {
    clearInterval(this.codeContentTimer2);
  },
};
</script>

<style lang="scss" scoped>
.change-phone {
  .blue-text {
    color: #3971ff !important;
  }
}
</style>
