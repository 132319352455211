<template>
  <van-nav-bar
    :title="title"
    left-arrow
    @click-left="onClickLeft"
    @click-right="onClickRight"
    class="header-nav"
    :fixed="true"
  >
    <!-- 当前对话 start -->
    <template #right>
      <p v-if="['nowChat', 'QuestionChat'].indexOf(page)!=-1 && hideInput">
        <img
          :src="require('@/assets/images/common_icon_history.png')"
          alt=""
        >
        历史对话
      </p>
      <p v-if="page=='knowledgeDetail'">
        <img
          :src="require('@/assets/images/common_icon_del.png')"
          alt=""
        >
        删除
      </p>
    </template>
    <!-- 当前对话 end -->
  </van-nav-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    page: {
      type: String,
      default: "",
    },
    left: {
      type: String,
      default: "",
    },
    hideInput: {
      type: Boolean,
      default: localStorage.getItem("hideInput")
        ? JSON.parse(localStorage.getItem("hideInput"))
        : true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    onClickLeft() {
      if (this.left) {
        this.$router.replace(this.left);
        this.$nextTick(() => {
          let container = document.getElementById("chatView");
          if (container && container.scrollHeight != null) {
            container.scrollTop = container.scrollHeight;
          }
        });
      } else {
        this.$toast.clear();
        this.$router.back(-1);
      }
    },
    onClickRight() {
      this.$emit("onClickRight");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.header-nav {
  height: 1.17rem;
  .van-icon {
    font-size: 0.47rem;
    color: #4a4a4a;
    font-weight: 500;
  }
  .van-nav-bar__title {
    font-size: 0.45rem;
    font-weight: 500;
    color: #000000;
  }
  .van-nav-bar__right p {
    display: flex;
    align-items: center;
  }
}
</style>
